import React, { useEffect, useState, forwardRef } from "react";
import { useDropzone } from "react-dropzone";
import ReactLoading from "react-loading";

const ImagePicker = forwardRef(({ setSelectedImage, selectedImage, setNewImage }, ref) => {
  const [url, setUrl] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      setIsLoading(true);
      setNewImage(true);
      setSelectedImage(file);
      const imageUrl = URL.createObjectURL(file);
      setUrl(imageUrl);
    }
  };

  useEffect(() => {
    if (selectedImage) {
      if (typeof selectedImage === "string") {
        setIsLoading(true);
        setUrl(selectedImage);
        console.log(selectedImage);
      } else if (selectedImage instanceof File) {
        const imageUrl = URL.createObjectURL(selectedImage);
        setUrl(imageUrl);
      }
    }
    setIsLoading(false);
  }, [selectedImage]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: "image/*" });

  useEffect(() => {
    // Cleanup the object URL when the component is unmounted or URL changes
    return () => {
      if (url && typeof url === "object") {
        URL.revokeObjectURL(url);
      }
    };
  }, [url]);

  return (
    <div className="imageUploadBox" ref={ref}>
      <div
        {...getRootProps()}
        style={
          url
            ? {
                border: "2px dashed #ccc",
                textAlign: "center",
                cursor: "pointer",
                width: "100%",
                height: "fit-content",
                padding: "12px",
                borderRadius: "8px",
                backgroundColor: "#355CE422",
              }
            : {
                border: "2px dashed #ccc",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                backgroundColor: "#355CE422",
                borderRadius: "8px",
                width: "100%",
                minWidth: "240px",
                minHeight: "180px",
                height: "fit-content",
                padding: "12px",
              }
        }
      >
        <input {...getInputProps()} />
        {isLoading ? (
          <div className="loading-image">
            <ReactLoading type="bars" color="var(--primary)" height={"40px"} width={"40px"} />
          </div>
        ) : !url ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.5"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 23.75C5.69035 23.75 6.25 24.3097 6.25 25C6.25 27.3923 6.25265 29.0608 6.42192 30.3198C6.58633 31.5428 6.88708 32.1903 7.34835 32.6517C7.80962 33.113 8.45725 33.4137 9.6802 33.5782C10.9391 33.7473 12.6077 33.75 15 33.75H25C27.3923 33.75 29.0608 33.7473 30.3198 33.5782C31.5428 33.4137 32.1903 33.113 32.6517 32.6517C33.113 32.1903 33.4137 31.5428 33.5782 30.3198C33.7473 29.0608 33.75 27.3923 33.75 25C33.75 24.3097 34.3097 23.75 35 23.75C35.6903 23.75 36.25 24.3097 36.25 25V25.0915C36.25 27.3708 36.25 29.208 36.0558 30.653C35.8542 32.1532 35.4227 33.4162 34.4195 34.4193C33.4162 35.4227 32.1532 35.8542 30.653 36.0558C29.208 36.25 27.3708 36.25 25.0915 36.25H14.9086C12.6292 36.25 10.792 36.25 9.34708 36.0558C7.8469 35.8542 6.58378 35.4227 5.58058 34.4195C4.57738 33.4162 4.1459 32.1532 3.9442 30.653C3.74993 29.208 3.74997 27.3708 3.75 25.0915C3.75 25.061 3.75 25.0305 3.75 25C3.75 24.3097 4.30965 23.75 5 23.75Z"
                fill="#1C274C"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.9999 3.75C20.3509 3.75 20.6856 3.89753 20.9224 4.15653L27.5891 11.4482C28.0549 11.9577 28.0196 12.7484 27.5101 13.2142C27.0006 13.68 26.2099 13.6446 25.7441 13.1351L21.2499 8.21967V26.6667C21.2499 27.357 20.6903 27.9167 19.9999 27.9167C19.3096 27.9167 18.7499 27.357 18.7499 26.6667V8.21967L14.2558 13.1351C13.79 13.6446 12.9993 13.68 12.4898 13.2142C11.9803 12.7484 11.9449 11.9577 12.4107 11.4482L19.0774 4.15653C19.3143 3.89753 19.6489 3.75 19.9999 3.75Z"
                fill="#1C274C"
              />
            </svg>
            <h4>Upload Image</h4>
          </div>
        ) : (
          <img
            src={url}
            alt="Selected"
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
            onLoad={() => setIsLoading(false)}
            onError={() => setIsLoading(false)}
          />
        )}
      </div>
    </div>
  );
});

export default ImagePicker;
