import { useEffect, useRef, useState } from "react";
import "./login.css";
import { InputBox } from "../components/inputBox/inputBox";
import { useNavigate } from "react-router-dom";
import { useData } from "../components/dataProvider/dataProvider";
import toast from "react-hot-toast";
import useMyFetch from "../components/fetch/fetch";

const Login = () => {
  const navigate = useNavigate();
  const { post2, post } = useMyFetch();
  const { setUserInfo } = useData();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const usernameInput = useRef(null);
  const passwordInput = useRef(null);
  const form = useRef(null);

  function KeyPressHandler(event, nextRef, isLast = false) {
    if (event.key === "Enter" && event.target.value !== "") {
      event.preventDefault();
      if (isLast) {
        if (userName !== "") {
          submitHandler(event);
        } else {
          usernameInput.current.focus();
        }
        return;
      }
      if (nextRef && nextRef.current) {
        nextRef.current.focus();
      }
    }
  }

  function submitHandler(event) {
    event.preventDefault();
    console.log("login form Submit");
    // console.log(userName, password);
    post("login", { userName, password }).then((response) => {
      setUserName("");
      setPassword("");

      if (response.status !== "success") {
        toast.error(response.message);
        usernameInput.current.focus();
        return;
      } else {
        const { data } = response;
        setUserInfo({ key: data.key, id: data.id, admin: true });
        // toast("Welcome " + data.name);
        navigate("/");
      }
    });
  }

  return (
    <main className="login-page">
      <form className="login-form" onSubmit={submitHandler} method="POST" ref={form}>
        <div className="deco"></div>

        <h1>Login Account</h1>
        <InputBox
          type="text"
          name="userName"
          display="UserName"
          value={userName}
          setValue={setUserName}
          isRequired={true}
          ref={usernameInput}
          onKeyPress={(e) => KeyPressHandler(e, passwordInput)}
        />
        <InputBox
          type="password"
          name="password"
          display="Password"
          value={password}
          setValue={setPassword}
          isRequired={true}
          isPassword={true}
          ref={passwordInput}
          onKeyPress={(e) => KeyPressHandler(e, null, true)}
        />
        <button className=" success">Login</button>
      </form>
    </main>
  );
};

export default Login;
