// useMyFetch.js
import { useNavigate } from "react-router-dom";
import { useData } from "../dataProvider/dataProvider"; // Adjust import path as per your file structure
import toast from "react-hot-toast";

const domain = "https://kozinbike.com/server/models/";
// const domain = "http://100.100.10.20/kozin/server/models/";

function decodeHTMLEntities(text) {
  const textArea = document.createElement("textarea");
  textArea.innerHTML = text;
  return textArea.value;
}

function decodeEntitiesRecursive(data) {
  if (typeof data === "string") {
    return decodeHTMLEntities(data);
  } else if (Array.isArray(data)) {
    return data.map(decodeEntitiesRecursive);
  } else if (typeof data === "object" && data !== null) {
    return Object.keys(data).reduce((acc, key) => {
      acc[key] = decodeEntitiesRecursive(data[key]);
      return acc;
    }, {});
  }
  return data;
}

const useMyFetch = () => {
  const { userInfo } = useData();
  const navigate = useNavigate();

  const post = async (where, body = {}) => {
    let formData = new FormData();
    formData.append("authKey", userInfo.key);
    formData.append("user_id", userInfo.id);
    Object.keys(body).forEach((key) => {
      formData.append(key, body[key]);
    });
    return fetch(domain + where, {
      method: "POST",
      body: formData,
    })
      .then((server_response) => server_response.json())
      .then((response) => {
        response = decodeEntitiesRecursive(response);
        console.log(response);
        if (response.message === "Key Doesn't Exist") {
          response.message = "Session TimeOut \n Please Login Again";
          navigate("/login");
          return response;
        }
        return response;
      })
      .catch((error) => {
        const errorMessage =
          error.message === "Failed to fetch" ? "Cannot Connect to Server" : error.message;
        return { status: "error", message: errorMessage };
      });
  };

  const post2 = async (where, body = {}) => {
    let formData = new FormData();
    formData.append("authKey", userInfo.key);
    formData.append("user_id", userInfo.id);
    Object.keys(body).forEach((key) => {
      formData.append(key, body[key]);
    });
    return fetch(domain + where, {
      method: "POST",
      body: formData,
    })
      .then((server_response) => server_response.text())
      .then((response) => {
        response = decodeEntitiesRecursive(response);
        console.log(response);
        if (response.message === "Key Doesn't Exist") {
          response.message = "Session TimeOut \n Please Login Again";
          navigate("/login");
          return response;
        }
        return response;
      })
      .catch((error) => {
        const errorMessage =
          error.message === "Failed to fetch" ? "Cannot Connect to Server" : error.message;
        return { status: "error", message: errorMessage };
      });
  };

  return { post, post2 };
};

export default useMyFetch;
