// DataContext.js
import React, { createContext, useContext, useEffect, useState } from "react";

const DataContext = createContext();

const getUserInfo = () => {
  const storedData = localStorage.getItem("userInfo");
  console.log(storedData);
  return storedData ? JSON.parse(storedData) : { key: "", id: "", admin: false };
  // return { key: "", id: "", admin: true };
};

export const DataProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(getUserInfo);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [bikes, setBikes] = useState([]);
  const [productInfo, setProductInfo] = useState(null);

  const productImageFolder = "https://kozinbike.com/uploaded_images/";

  useEffect(() => {
    if (userInfo) {
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
    }
  }, [userInfo]);

  return (
    <DataContext.Provider
      value={{
        bikes,
        setBikes,
        userInfo,
        setUserInfo,
        loadingScreen,
        setLoadingScreen,
        productImageFolder,
        productInfo,
        setProductInfo,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
  return useContext(DataContext);
};
