import "./home.css";
import BoxDisplay from "../components/boxDisplay/boxDisplay";
// import BrandCard from "../components/brandCard/brandCard";
import { SearchBox } from "../components/inputBox/inputBox";
import { useEffect, useState } from "react";
import { useData } from "../components/dataProvider/dataProvider";
import useMyFetch from "../components/fetch/fetch";
import { toast } from "react-hot-toast";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const Home = () => {
  const location = useLocation();

  const [bikesLoading, setBikesLoading] = useState(true);
  const { userInfo, bikes, setBikes } = useData();
  const [search, setSearch] = useState("");
  const { post } = useMyFetch();
  const [connected, setConnected] = useState(true);
  const [first, setFirst] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTicketData = () => {
      post("product").then((res) => {
        if (res.status !== "success") {
          // if (res.message)
          if (connected) {
            toast.error(res.message);
            setConnected(false);
            // show Some Error illustration[/]
          }
          return;
        }
        setBikes(res.data);
        setBikesLoading(false);
        setConnected(true);
        setFirst(false);
      });
    };
    if (location.pathname === "/" && first) {
      fetchTicketData();
    }
  }, [location.pathname]);

  return (
    <div className="home">
      <div className="search-container">
        <SearchBox type="text" value={search} setValue={setSearch} placeholder="Search..." />
      </div>
      {userInfo.admin && (
        <div className="add-new-lottery" onClick={() => navigate("/new")}>
          <svg title="New Lottery">
            <use xlinkHref="#plus-dark-icon" />
          </svg>
        </div>
      )}
      <Outlet />

      <BoxDisplay
        data={userInfo.admin ? bikes : bikes.filter((bike) => parseInt(bike.visible) === 1)}
        max_height="calc(100% - 40px)"
        is_loading={bikesLoading}
        admin={true}
        search={search}
      />
    </div>
  );
};

export default Home;
// actions={[
//   { type: "view", handler: toggleView },
//   { type: "edit", handler: handleEdit },
//   { type: "delete", handler: handleDelete },
// ]}
