import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
// import ProductNotFound from "./productNotFound";
import toast from "react-hot-toast";
import "./productDetail.css";
import { useEffect, useState } from "react";
import useMyFetch from "../components/fetch/fetch";
import { useData } from "../components/dataProvider/dataProvider";

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [productExist, setProductExist] = useState(true);
  const [images, setImages] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [menuExpand, setMenuExpand] = useState(false);
  const { post } = useMyFetch();
  const { bikes, setBikes, userInfo, productImageFolder, productInfo, setProductInfo } = useData();

  useEffect(() => {
    // Function to fetch ticket data
    const fetchProduct = () => {
      post("product", { id }).then((res) => {
        if (res.status !== "success") {
          if (res.message === "Doesn't Exist") {
            console.log(res.message);
            setProductExist(false);
            setLoading(false);
          }
          return;
        }
        setProductInfo(res.data);
        const local_images = JSON.parse(res.data.images);
        setImages(local_images);
        const mainImage = local_images.find((image) => parseInt(image.main) === 1);
        setSelectedImage(mainImage);
        setLoading(false);
      });
    };
    fetchProduct();
  }, []);

  const changeImage = (image) => {
    setSelectedImage(image);
  };

  const CopyToClipboard = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(window.location.href)
        .then(() => {
          toast.success("Link copied to clipboard!");
        })
        .catch((err) => {
          toast.error("Failed to copy text.");
          console.error("Error copying text:", err);
        });
    } else {
      // Fallback for older browsers
      const textArea = document.createElement("textarea");
      textArea.value = window.location.href;
      document.body.appendChild(textArea);
      // textArea.focus();
      textArea.select();
      try {
        document.execCommand("copy");
        toast.success("Link copied to clipboard!");
      } catch (err) {
        toast.error("Failed to copy text.");
        console.error("Error copying text:", err);
      }
      document.body.removeChild(textArea);
    }
  };

  const toggleView = () => {
    console.log({ id: productInfo.id, visible: parseInt(productInfo.visible) ? 0 : 1 });
    post("product/toggle", {
      id: productInfo.id,
      visible: parseInt(productInfo.visible) ? 0 : 1,
    }).then((res) => {
      if (res.status !== "success") {
        toast.error(res.message);
        return;
      }
      setBikes(bikes.map((item) => (item.id === productInfo.id ? res.data : item)));
      setProductInfo(res.data);
    });
    // toast("view toggled");
  };

  return (
    <div className="productDetail">
      <div className="header">
        <div className="icons">
          <svg title="back" onClick={() => navigate(-1)}>
            <use xlinkHref="#back-icon" />
          </svg>
        </div>

        <p className="product-id">#{id}</p>
        {productExist && !loading ? (
          <div className="icons right">
            <svg title="share" onClick={CopyToClipboard}>
              <use xlinkHref="#share-icon" />
            </svg>
            {userInfo.admin &&
              (menuExpand ? (
                <svg title="menu" onClick={() => setMenuExpand(false)}>
                  <use xlinkHref="#cross-icon" />
                </svg>
              ) : (
                <svg title="menu" onClick={() => setMenuExpand(true)}>
                  <use xlinkHref="#menu-vertical-icon" />
                </svg>
              ))}
          </div>
        ) : (
          <div className="icons"></div>
        )}
        {!loading && productExist && (
          <ul className={menuExpand ? "menu slideIn" : "menu"}>
            <li
              onClick={() => {
                navigate("edit");
                setMenuExpand(false);
              }}
            >
              <svg title="edit">
                <use xlinkHref="#edit-icon" />
              </svg>
              <p>Edit</p>
            </li>
            <li
              onClick={() => {
                navigate("delete");
                setMenuExpand(false);
              }}
            >
              <svg title="delete">
                <use xlinkHref="#delete-icon" />
              </svg>
              <p>Delete</p>
            </li>
            {parseInt(productInfo.visible) === 1 ? (
              <li onClick={toggleView}>
                <svg title="hide">
                  <use xlinkHref="#eye-slashed-icon" />
                </svg>
                <p>Hide</p>
              </li>
            ) : (
              <li onClick={toggleView}>
                <svg viewBox="0 0 32 32">
                  <path
                    d="M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z"
                    fill="var(--success)"
                  />
                  <path
                    d="M30.94 15.66C29.7638 12.6176 27.722 9.98662 25.0668 8.09209C22.4116 6.19756 19.2595 5.12257 16 5C12.7405 5.12257 9.58839 6.19756 6.9332 8.09209C4.27802 9.98662 2.23616 12.6176 1.06 15.66C0.980569 15.8797 0.980569 16.1203 1.06 16.34C2.23616 19.3824 4.27802 22.0134 6.9332 23.9079C9.58839 25.8024 12.7405 26.8774 16 27C19.2595 26.8774 22.4116 25.8024 25.0668 23.9079C27.722 22.0134 29.7638 19.3824 30.94 16.34C31.0194 16.1203 31.0194 15.8797 30.94 15.66ZM16 22.5C14.7144 22.5 13.4577 22.1188 12.3888 21.4046C11.3199 20.6903 10.4868 19.6752 9.99479 18.4874C9.50282 17.2997 9.37409 15.9928 9.6249 14.7319C9.8757 13.471 10.4948 12.3128 11.4038 11.4038C12.3128 10.4948 13.471 9.8757 14.7319 9.6249C15.9928 9.37409 17.2997 9.50281 18.4874 9.99478C19.6752 10.4868 20.6903 11.3199 21.4046 12.3888C22.1188 13.4577 22.5 14.7144 22.5 16C22.4974 17.7231 21.8117 19.3749 20.5933 20.5933C19.3749 21.8117 17.7231 22.4974 16 22.5Z"
                    fill="var(--success)"
                  />
                </svg>
                <p>Show</p>
              </li>
            )}
          </ul>
        )}
      </div>

      {!loading && selectedImage && productExist && (
        <div className="aDiv">
          <img src={productImageFolder + selectedImage.url} alt="" className="main_image" />
          <div className="sub_images">
            {images.map((image) => (
              <img
                src={productImageFolder + image.url}
                alt=""
                className={parseInt(image.id) === parseInt(selectedImage.id) ? "selected" : ""}
                key={image.id}
                onClick={() => changeImage(image)}
                style={parseInt(image.main) === 1 ? { order: "-1" } : {}}
              />
            ))}
          </div>
          <div className="price-container">
            <h3>{productInfo.name}</h3>
            <p className="price">
              <span className="ks">Ks</span> {parseInt(productInfo.price).toLocaleString()}
            </p>
          </div>
          <div className="detail-container">
            <h1>Detail</h1>
            <div className="detail">
              <p>{productInfo.detail}</p>
            </div>
          </div>
        </div>
      )}
      {loading && (
        <div className="loading-indicator">
          <svg id="turtle-loading-icon" viewBox="0 0 500 500" width="280px" maxwidth="100%">
            <g id="turtles">
              <path
                className="st0"
                d="M125.2,349.1c0,0,2.7,11.2,3.4,16.1h-27.1v-17.4L125.2,349.1z"
              ></path>
              <path
                className="st1"
                d="M128.6,367h-27.1c-1,0-1.8-0.8-1.8-1.8v-17.4c0-0.5,0.2-1,0.6-1.3c0.4-0.3,0.8-0.5,1.3-0.5l23.7,1.3
c0.8,0,1.5,0.6,1.7,1.4c0.1,0.5,2.7,11.4,3.4,16.2c0.1,1-0.5,1.9-1.5,2C128.8,367,128.7,367,128.6,367L128.6,367z M103.3,363.4
h23.2c-0.7-4-2.1-9.9-2.7-12.5l-20.4-1.1L103.3,363.4z"
              ></path>
              <path
                className="st0"
                d="M210.8,320.3c0,0,19.8,14.8,28.6,30.2l-38.5-1.4c0,0-10.6-24.5-21.1-27.1c0,0,14.1-15.1,17.1-14.6
S210.8,320.3,210.8,320.3z"
              ></path>
              <path
                className="st1"
                d="M239.4,352.3L239.4,352.3l-38.6-1.3c-0.7,0-1.3-0.4-1.6-1.1c-2.8-6.6-11.9-24.1-19.9-26
c-1-0.2-1.6-1.2-1.3-2.2c0.1-0.3,0.2-0.6,0.4-0.8c9.8-10.5,16-15.6,18.6-15.2c3.2,0.5,11.4,9.4,14.8,13.3c2,1.5,20.4,15.6,29,30.7
c0.5,0.9,0.2,2-0.7,2.5C240.1,352.3,239.8,352.4,239.4,352.3L239.4,352.3z M202.1,347.4l34,1.2c-9.2-13.9-26.2-26.7-26.4-26.9
c-0.1-0.1-0.2-0.2-0.3-0.3c-4.8-5.5-10.9-11.6-12.7-12.2c-1.9,0.5-8.2,6.3-13.8,12.1C192,326.2,200.1,343,202.1,347.4z"
              ></path>
              <path
                className="st0"
                d="M198.2,313c0,0,6.3,13.9,36.2,7.3s45.4-29.8,30.2-41.8c-19.5-15.4-32.2,10.3-51.8,4.7L198.2,313z"
              ></path>
              <path
                className="st1"
                d="M219.6,323.9c-18.2,0-22.8-9.6-23-10.1c-0.2-0.5-0.2-1.1,0-1.5l14.6-29.9c0.4-0.8,1.3-1.2,2.1-0.9
c7.9,2.3,14.6-1,21.7-4.5c9.3-4.6,18.9-9.3,30.8,0.1c5.5,4.3,7.6,10.3,6.1,16.8c-2.9,12.4-18.1,24-37.1,28.2
C229.8,323.2,224.7,323.8,219.6,323.9z M200.3,312.9c1.8,2.5,9.7,10.9,33.7,5.6c20.6-4.6,32.3-16.4,34.4-25.5
c1.2-5.2-0.5-9.7-4.8-13.1c-10-7.9-17.9-4.1-26.9,0.3c-7,3.4-14.2,6.9-22.8,5.1L200.3,312.9z M198.2,312.9L198.2,312.9z"
              ></path>
              <path
                className="st0"
                d="M172.7,365.2h34.9c2-9.5-4.7-33.1-18.2-45L164.1,334c0,0,8,10.4,8,20C172,360.5,171.8,363.2,172.7,365.2z"
              ></path>
              <path
                className="st1"
                d="M207.6,367h-34.9c-0.7,0-1.3-0.4-1.6-1c-1-2-0.9-4.2-0.9-8.6c0-1,0-2.1,0-3.3c0-8.9-7.5-18.8-7.6-18.9
c-0.6-0.8-0.4-1.9,0.4-2.5c0.1-0.1,0.1-0.1,0.2-0.1l25.4-13.8c0.7-0.4,1.5-0.3,2.1,0.2c14,12.3,20.9,36.4,18.8,46.7
C209.2,366.4,208.5,367,207.6,367z M174,363.4h32c1-9.7-5.3-30-16.9-40.9l-22.4,12.2c2.3,3.5,7.1,11.6,7.1,19.4c0,1.2,0,2.4,0,3.3
C173.7,359.4,173.7,361.4,174,363.4z"
              ></path>
              <path
                className="st0"
                d="M113,365.2c0.4-6-1.3-16.1-1.3-16.1l-32.8-4.3c0,0-3.8,13.2-2.3,20.4H113z"
              ></path>
              <path
                className="st1"
                d="M113,367H76.5c-0.8,0-1.6-0.6-1.8-1.4c-1.7-7.5,2.1-20.7,2.3-21.2c0.2-0.9,1.1-1.4,2-1.3l32.8,4.3
c0.8,0.1,1.4,0.7,1.6,1.5c0.1,0.4,1.8,10.4,1.4,16.5C114.8,366.3,114,367,113,367z M78.1,363.4h33.2c-0.1-4.2-0.5-8.5-1.2-12.6
l-30-3.9C79.3,350.1,77.5,358.1,78.1,363.4L78.1,363.4z"
              ></path>
              <path
                className="st0"
                d="M50.4,334.1c0,0-29.7,8.1-24.7,13.4s32.4,3.4,47.6-4.1L50.4,334.1z"
              ></path>
              <path
                className="st1"
                d="M39.8,352.3c-7.2,0-13.1-1.1-15.4-3.6c-0.9-0.9-1.3-2.3-0.9-3.5c0.4-1.4,3.5-6.6,26.5-12.9
c0.4-0.1,0.8-0.1,1.2,0.1l22.9,9.3c0.7,0.3,1.1,0.9,1.1,1.6c0,0.7-0.4,1.4-1,1.7C64.7,349.7,50.7,352.3,39.8,352.3z M50.3,336
c-12.2,3.4-23,8.1-23.3,10.2c3.7,3.9,26,3.4,41.7-2.8L50.3,336z"
              ></path>
              <path
                className="st2"
                d="M217.8,280.8c0,0-24.9-6.6-41.1-20.2s-33.5-20.2-68-10.3c-34.5,9.9-38.8,36.8-44.8,49.4S46.6,318.6,44,326.3
s34.8,22.9,67.7,22.9s64.7-16.6,86.6-36.2S217.8,280.8,217.8,280.8z"
              ></path>
              <path
                className="st1"
                d="M111.7,351c-28.5,0-62-11.5-68.4-20.5c-1.1-1.3-1.5-3.1-1-4.7c1.4-3.9,4.6-7.3,8.3-11.2c4-4.2,8.6-9,11.7-15.5
c0.9-2,1.9-4.4,2.9-7c5.3-13.9,13.3-34.8,43-43.4c35.2-10.1,53.3-3.1,69.7,10.6c15.8,13.2,40.2,19.8,40.4,19.9
c0.7,0.2,1.2,0.7,1.3,1.4c0.1,0.6,2.2,13.8-20.1,33.9C173.8,337.3,141,351,111.7,351z M137.9,247.3c-8.1,0-17.4,1.5-28.7,4.7
c-28,8.1-35.3,27.2-40.7,41.2c-1,2.6-2,5.2-3,7.3c-3.3,7-8.1,12-12.3,16.4c-3.5,3.6-6.4,6.8-7.5,9.9c-0.2,0.4,0.3,1.1,0.5,1.5
c5.2,7.3,37.3,19,65.4,19c28.5,0,60.4-13.3,85.4-35.7c17.1-15.3,18.9-26.1,19-29.4c-5.4-1.6-26.1-8.1-40.5-20.2
C164.9,253.1,154,247.3,137.9,247.3z"
              ></path>
              <path
                className="st1"
                d="M112.6,339h-0.9c-36.6-0.3-62.4-18.2-63.4-19c-0.8-0.6-1-1.7-0.5-2.5c0.6-0.8,1.7-1,2.5-0.5
c0.2,0.2,25.9,18,61.4,18.3c37.4,0.4,71.9-21.1,94.8-58.7c0.5-0.9,1.6-1.1,2.5-0.6c0.9,0.5,1.1,1.6,0.6,2.5
C186.2,317,150.9,339,112.6,339z"
              ></path>
              <path
                className="st1"
                d="M130.9,299.1h-0.1l-42.1-2.9c-0.7,0-1.4-0.5-1.6-1.2l-8.6-26.5c-0.3-1,0.3-2,1.2-2.2c0.9-0.3,1.9,0.2,2.2,1.1
l8.3,25.3l40,2.8l23.3-24.1l-5.9-24.3c-0.2-1,0.4-2,1.3-2.2s2,0.4,2.2,1.3l6.2,25.3c0.2,0.6,0,1.2-0.4,1.7l-24.6,25.5
C131.8,298.9,131.4,299.1,130.9,299.1z"
              ></path>
              <path
                className="st1"
                d="M74.7,332.5c-0.2,0-0.4,0-0.7-0.1c-0.9-0.4-1.4-1.4-1-2.4l14.1-36.3c0.3-0.9,1.3-1.5,2.3-1.1
c0.9,0.3,1.5,1.3,1.1,2.3c0,0.1,0,0.1-0.1,0.2l-14.1,36.3C76.1,332.1,75.4,332.6,74.7,332.5z"
              ></path>
              <path
                className="st1"
                d="M140.4,335c-0.8,0-1.5-0.6-1.8-1.4l-9.5-35.9c-0.3-1,0.3-2,1.3-2.2s2,0.3,2.2,1.3l9.5,35.9
c0.3,1-0.3,2-1.3,2.2C140.7,335,140.5,335,140.4,335z"
              ></path>
              <path
                className="st1"
                d="M194.4,296.9c-0.3,0-0.6-0.1-0.9-0.3l-39-23.2c-0.9-0.5-1.2-1.6-0.7-2.5c0.5-0.9,1.6-1.2,2.5-0.7
c0,0,0.1,0,0.1,0.1l39,23.2c0.9,0.5,1.1,1.6,0.6,2.5C195.6,296.5,195,296.9,194.4,296.9L194.4,296.9z"
              ></path>
              <circle className="st1" cx="244.7" cy="291" r="6.4"></circle>
            </g>
            <g id="speech-bubble">
              <path
                className="st3"
                d="M423.9,134.1H73.2c-15.6,0-28.2,12.6-28.2,28.2c0,15.6,12.6,28.2,28.2,28.2h257.2v28.7l24-28.7H424
c15.6,0,28.2-12.6,28.2-28.2S439.5,134.1,423.9,134.1C424,134.1,424,134.1,423.9,134.1L423.9,134.1z"
              ></path>
              <path
                className="st1"
                d="M330.4,220.9c-0.2,0-0.4,0-0.6-0.1c-0.7-0.3-1.2-0.9-1.2-1.7v-26.9H73.2c-16.6,0-30-13.4-30-30s13.4-30,30-30
h350.8c16.6,0,30,13.4,30,30s-13.4,30-30,30l0,0h-68.7l-23.4,28.1C331.4,220.7,330.9,220.9,330.4,220.9z M73.1,135.9
c-14.6,0-26.4,11.8-26.4,26.4s11.8,26.4,26.4,26.4h257.2c1,0,1.8,0.8,1.8,1.8v23.7l20.8-24.9c0.3-0.4,0.9-0.7,1.4-0.6H424
c14.6,0,26.4-11.8,26.4-26.4s-11.8-26.4-26.4-26.4L73.1,135.9z"
              ></path>
              <path
                className="st4"
                d="M374.7,144.9H73.2c-9.6,0.2-17.1,8.2-16.9,17.7c0.2,9.2,7.7,16.7,16.9,16.9h301.6V144.9z"
              ></path>
              <path
                className="st1"
                d="M374.7,181.3H73.2c-10.6-0.2-18.9-9-18.7-19.6c0.2-10.2,8.5-18.4,18.7-18.7h301.6c1,0,1.8,0.8,1.8,1.8v34.6
C376.5,180.5,375.7,181.3,374.7,181.3z M73.2,146.7c-8.6,0-15.5,6.9-15.5,15.5s6.9,15.5,15.5,15.5h299.8v-31H73.2z"
              ></path>
              <path className="st1" d="M182.8,169.2v-15.4h3.7V166h6v3.2L182.8,169.2z"></path>
              <path
                className="st1"
                d="M209.6,161.4c0,1.1-0.2,2.3-0.6,3.3c-0.4,1-1,1.9-1.8,2.6c-0.8,0.7-1.7,1.3-2.7,1.7c-1.1,0.4-2.2,0.6-3.4,0.6
c-1.1,0-2.3-0.2-3.3-0.6c-1-0.4-1.9-0.9-2.7-1.7c-0.8-0.7-1.3-1.6-1.7-2.6c-0.4-1.1-0.7-2.2-0.6-3.3c0-1.1,0.2-2.3,0.6-3.3
c0.4-1,1-1.8,1.7-2.5c0.8-0.7,1.7-1.3,2.7-1.6c1.1-0.4,2.2-0.6,3.3-0.6c1.1,0,2.3,0.2,3.4,0.6c1,0.3,1.9,0.9,2.7,1.6
c0.8,0.7,1.4,1.6,1.8,2.5C209.4,159.1,209.6,160.3,209.6,161.4z M205.6,161.4c0-0.6-0.1-1.3-0.3-1.9c-0.2-0.6-0.5-1.1-0.9-1.5
c-0.4-0.4-0.9-0.8-1.4-1c-0.6-0.2-1.2-0.4-1.8-0.4c-0.6,0-1.2,0.1-1.8,0.4c-0.5,0.2-1,0.6-1.4,1c-0.4,0.4-0.7,0.9-0.9,1.5
c-0.4,1.2-0.4,2.6,0,3.8c0.2,0.6,0.5,1.1,0.9,1.5c0.4,0.4,0.9,0.8,1.4,1c1.7,0.8,3.7,0.4,5-1c0.4-0.4,0.7-1,0.9-1.5
C205.5,162.7,205.6,162.1,205.6,161.4L205.6,161.4z"
              ></path>
              <path
                className="st1"
                d="M222.1,169.2l-1.2-3h-6l-1.1,3h-4l6.4-15.4h3.6l6.4,15.4H222.1z M218,157.9l-2,5.3h3.9L218,157.9z"
              ></path>
              <path
                className="st1"
                d="M241.9,161.4c0,1.2-0.2,2.4-0.8,3.5c-0.5,0.9-1.2,1.8-2,2.4c-0.8,0.6-1.8,1.1-2.8,1.4c-1,0.3-2.1,0.4-3.1,0.4
h-5.7v-15.4h5.6c1.1,0,2.2,0.1,3.2,0.4c1,0.2,2,0.7,2.8,1.3c0.8,0.6,1.5,1.4,2,2.4C241.6,158.9,241.9,160.2,241.9,161.4z
 M238,161.4c0-0.7-0.1-1.5-0.4-2.2c-0.3-0.5-0.6-1-1.1-1.4c-0.5-0.4-1-0.6-1.6-0.7c-0.6-0.1-1.2-0.2-1.9-0.2h-1.9v9h1.8
c0.7,0,1.3-0.1,1.9-0.2c0.6-0.1,1.1-0.4,1.6-0.8c0.5-0.4,0.9-0.8,1.1-1.4C237.9,162.9,238,162.2,238,161.4L238,161.4z"
              ></path>
              <path className="st1" d="M244.3,169.2v-15.4h3.7v15.4L244.3,169.2z"></path>
              <path
                className="st1"
                d="M261,169.2l-6.2-10.1h-0.1l0.1,10.1h-3.6v-15.4h4.2l6.2,10h0.1l-0.1-10h3.6v15.4L261,169.2z"
              ></path>
              <path
                className="st1"
                d="M279.5,169.2c-1.1,0.3-2.2,0.4-3.3,0.4c-1.2,0-2.3-0.2-3.4-0.6c-1-0.4-1.9-0.9-2.7-1.6
c-0.8-0.7-1.4-1.6-1.8-2.6c-0.9-2.1-0.9-4.5,0-6.7c0.4-1,1-1.8,1.8-2.6c0.8-0.7,1.7-1.3,2.6-1.6c1.1-0.4,2.2-0.6,3.3-0.6
c1.2,0,2.3,0.2,3.4,0.6c0.9,0.3,1.8,0.8,2.5,1.5l-2.4,2.7c-0.4-0.4-0.9-0.8-1.4-1c-0.6-0.3-1.3-0.4-2-0.4c-0.6,0-1.2,0.1-1.8,0.4
c-0.5,0.2-1,0.6-1.4,1c-0.4,0.4-0.7,1-1,1.5c-0.2,0.6-0.3,1.3-0.3,1.9c0,0.7,0.1,1.4,0.3,2c0.2,0.6,0.5,1.1,0.9,1.5
c0.4,0.4,0.9,0.8,1.5,1c0.6,0.2,1.3,0.4,2,0.4c0.4,0,0.8,0,1.2-0.1c0.4-0.1,0.7-0.2,1.1-0.3v-2.8h-2.9v-3h6.4v8.1
C281.3,168.7,280.4,169,279.5,169.2z"
              ></path>
              <path
                className="st1"
                d="M289,167.3c0,0.6-0.2,1.1-0.6,1.5c-0.4,0.4-1,0.6-1.5,0.6c-0.3,0-0.6-0.1-0.9-0.2c-0.3-0.1-0.5-0.3-0.7-0.4
c-0.2-0.2-0.4-0.4-0.5-0.7c-0.2-0.5-0.2-1.1,0-1.7c0.2-0.5,0.7-0.9,1.2-1.1c0.3-0.1,0.6-0.2,0.9-0.2c0.6,0,1.1,0.2,1.5,0.6
C288.8,166.1,289,166.7,289,167.3z"
              ></path>
              <path
                className="st1"
                d="M295.5,167.3c0,0.6-0.2,1.1-0.6,1.5c-0.4,0.4-1,0.6-1.5,0.6c-0.3,0-0.6-0.1-0.9-0.2c-0.3-0.1-0.5-0.3-0.7-0.4
c-0.2-0.2-0.4-0.4-0.5-0.7c-0.2-0.5-0.2-1.1,0-1.7c0.1-0.3,0.3-0.5,0.5-0.7c0.2-0.2,0.4-0.3,0.7-0.5c0.3-0.1,0.6-0.2,0.9-0.2
c0.6,0,1.1,0.2,1.5,0.6C295.3,166.1,295.5,166.7,295.5,167.3z"
              ></path>
              <path
                className="st1"
                d="M302,167.3c0,0.6-0.2,1.1-0.6,1.5c-0.4,0.4-1,0.6-1.5,0.6c-0.3,0-0.6-0.1-0.9-0.2c-0.5-0.2-0.9-0.6-1.2-1.1
c-0.2-0.5-0.2-1.1,0-1.7c0.1-0.3,0.3-0.5,0.5-0.7c0.2-0.2,0.4-0.4,0.7-0.5c0.3-0.1,0.6-0.2,0.9-0.2c0.6,0,1.1,0.2,1.5,0.6
C301.8,166.1,302,166.7,302,167.3z"
              ></path>
            </g>
            <g id="device">
              <path
                className="st4"
                d="M292.7,246.1L269,350.6h-64.4c-4.2,0-7.6,3.4-7.6,7.6l0,0c0,4.2,3.4,7.7,7.6,7.7c0,0,0,0,0,0h223.8l27.8-120
c1.3-5.7-2.2-11.5-8-12.8c-0.8-0.2-1.6-0.3-2.4-0.3H309.2C301.3,232.9,294.5,238.4,292.7,246.1z"
              ></path>
              <path
                className="st1"
                d="M428.4,367.8H204.6c-5.2,0-9.5-4.2-9.5-9.5c0-5.2,4.2-9.5,9.5-9.5h62.9L291,245.7l0,0
c1.9-8.6,9.5-14.6,18.3-14.6h136.5c6.9,0,12.5,5.6,12.5,12.5c0,1-0.1,1.9-0.3,2.8l-27.8,120C430,367.2,429.2,367.8,428.4,367.8z
 M204.6,352.5c-3.2,0.1-5.7,2.9-5.6,6.1c0.1,3,2.6,5.5,5.6,5.6h222.4l27.5-118.6c1.1-4.8-1.9-9.5-6.6-10.6c-0.7-0.2-1.3-0.2-2-0.2
H309.2c-7.1,0-13.2,4.9-14.8,11.8l-23.8,104.5c-0.2,0.8-0.9,1.4-1.8,1.4H204.6z"
              ></path>
              <path
                className="st1"
                d="M431.9,352.5H269c-1-0.1-1.8-0.9-1.7-1.9c0.1-0.9,0.8-1.6,1.7-1.7h163c1,0.1,1.8,0.9,1.7,1.9
C433.6,351.7,432.9,352.4,431.9,352.5L431.9,352.5z"
              ></path>
              <ellipse
                className="st5"
                transform="matrix(0.7382 -0.6746 0.6746 0.7382 -105.0194 324.5627)"
                cx="365.7"
                cy="297.6"
                rx="11.7"
                ry="9.8"
              ></ellipse>
              <path
                className="st1"
                d="M363.7,310.1c-3.2,0.1-6.3-1.3-8.3-3.7c-2.1-2.5-2.9-5.9-2.2-9.1c1.2-6.7,7.7-12.2,14.4-12.2
c3.2-0.1,6.3,1.3,8.3,3.7c2.1,2.5,2.9,5.9,2.2,9.1C376.9,304.7,370.5,310.1,363.7,310.1z M367.6,288.7c-5.3,0.2-9.8,4-10.9,9.2
c-0.5,2.2,0.1,4.4,1.4,6.1c1.4,1.6,3.4,2.5,5.6,2.4c5.3-0.2,9.8-4,10.9-9.2c0.5-2.2-0.1-4.4-1.4-6.1
C371.8,289.5,369.7,288.7,367.6,288.7z"
              ></path>
            </g>
            <g id="line">
              <path
                className="st1"
                d="M485.2,367.8H11.9c-1,0-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8h473.2c1,0,1.8,0.8,1.8,1.8
C487,367,486.2,367.8,485.2,367.8z"
              ></path>
            </g>
          </svg>
        </div>
      )}

      {!productExist && (
        <div
          style={{
            width: "100%",
            height: "80%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "10%",
          }}
        >
          <svg width="240" height="320" viewBox="0 0 797.5 834.5">
            <ellipse cx="308.5" cy="780" rx="308.5" ry="54.5" fill="#3f3d56" />
            <circle cx="496" cy="301.5" r="301.5" fill="#3f3d56" />
            <circle cx="496" cy="301.5" r="248.89787" opacity="0.05" />
            <circle cx="496" cy="301.5" r="203.99362" opacity="0.05" />
            <circle cx="496" cy="301.5" r="146.25957" opacity="0.05" />
            <path
              d="M398.42029,361.23224s-23.70394,66.72221-13.16886,90.42615,27.21564,46.52995,27.21564,46.52995S406.3216,365.62186,398.42029,361.23224Z"
              transform="translate(-201.25 -32.75)"
              fill="#d0cde1"
            />
            <path
              d="M398.42029,361.23224s-23.70394,66.72221-13.16886,90.42615,27.21564,46.52995,27.21564,46.52995S406.3216,365.62186,398.42029,361.23224Z"
              transform="translate(-201.25 -32.75)"
              opacity="0.1"
            />
            <path
              d="M415.10084,515.74682s-1.75585,16.68055-2.63377,17.55847.87792,2.63377,0,5.26754-1.75585,6.14547,0,7.02339-9.65716,78.13521-9.65716,78.13521-28.09356,36.8728-16.68055,94.81576l3.51169,58.82089s27.21564,1.75585,27.21564-7.90132c0,0-1.75585-11.413-1.75585-16.68055s4.38962-5.26754,1.75585-7.90131-2.63377-4.38962-2.63377-4.38962,4.38961-3.51169,3.51169-4.38962,7.90131-63.2105,7.90131-63.2105,9.65716-9.65716,9.65716-14.92471v-5.26754s4.38962-11.413,4.38962-12.29093,23.70394-54.43127,23.70394-54.43127l9.65716,38.62864,10.53509,55.3092s5.26754,50.04165,15.80262,69.356c0,0,18.4364,63.21051,18.4364,61.45466s30.72733-6.14547,29.84941-14.04678-18.4364-118.5197-18.4364-118.5197L533.62054,513.991Z"
              transform="translate(-201.25 -32.75)"
              fill="#2f2e41"
            />
            <path
              d="M391.3969,772.97846s-23.70394,46.53-7.90131,48.2858,21.94809,1.75585,28.97148-5.26754c3.83968-3.83968,11.61528-8.99134,17.87566-12.87285a23.117,23.117,0,0,0,10.96893-21.98175c-.463-4.29531-2.06792-7.83444-6.01858-8.16366-10.53508-.87792-22.826-10.53508-22.826-10.53508Z"
              transform="translate(-201.25 -32.75)"
              fill="#2f2e41"
            />
            <path
              d="M522.20753,807.21748s-23.70394,46.53-7.90131,48.28581,21.94809,1.75584,28.97148-5.26754c3.83968-3.83969,11.61528-8.99134,17.87566-12.87285a23.117,23.117,0,0,0,10.96893-21.98175c-.463-4.29531-2.06792-7.83444-6.01857-8.16367-10.53509-.87792-22.826-10.53508-22.826-10.53508Z"
              transform="translate(-201.25 -32.75)"
              fill="#2f2e41"
            />
            <circle cx="295.90488" cy="215.43252" r="36.90462" fill="#ffb8b8" />
            <path
              d="M473.43048,260.30832S447.07,308.81154,444.9612,308.81154,492.41,324.62781,492.41,324.62781s13.70743-46.39439,15.81626-50.61206Z"
              transform="translate(-201.25 -32.75)"
              fill="#ffb8b8"
            />
            <path
              d="M513.86726,313.3854s-52.67543-28.97148-57.943-28.09356-61.45466,50.04166-60.57673,70.2339,7.90131,53.55335,7.90131,53.55335,2.63377,93.05991,7.90131,93.93783-.87792,16.68055.87793,16.68055,122.90931,0,123.78724-2.63377S513.86726,313.3854,513.86726,313.3854Z"
              transform="translate(-201.25 -32.75)"
              fill="#d0cde1"
            />
            <path
              d="M543.2777,521.89228s16.68055,50.91958,2.63377,49.16373-20.19224-43.89619-20.19224-43.89619Z"
              transform="translate(-201.25 -32.75)"
              fill="#ffb8b8"
            />
            <path
              d="M498.50359,310.31267s-32.48318,7.02339-27.21563,50.91957,14.9247,87.79237,14.9247,87.79237l32.48318,71.11182,3.51169,13.16886,23.70394-6.14547L528.353,425.32067s-6.14547-108.86253-14.04678-112.37423A33.99966,33.99966,0,0,0,498.50359,310.31267Z"
              transform="translate(-201.25 -32.75)"
              fill="#d0cde1"
            />
            <polygon
              points="277.5 414.958 317.885 486.947 283.86 411.09 277.5 414.958"
              opacity="0.1"
            />
            <path
              d="M533.896,237.31585l.122-2.82012,5.6101,1.39632a6.26971,6.26971,0,0,0-2.5138-4.61513l5.97581-.33413a64.47667,64.47667,0,0,0-43.1245-26.65136c-12.92583-1.87346-27.31837.83756-36.182,10.43045-4.29926,4.653-7.00067,10.57018-8.92232,16.60685-3.53926,11.11821-4.26038,24.3719,3.11964,33.40938,7.5006,9.18513,20.602,10.98439,32.40592,12.12114,4.15328.4,8.50581.77216,12.35457-.83928a29.721,29.721,0,0,0-1.6539-13.03688,8.68665,8.68665,0,0,1-.87879-4.15246c.5247-3.51164,5.20884-4.39635,8.72762-3.9219s7.74984,1.20031,10.062-1.49432c1.59261-1.85609,1.49867-4.559,1.70967-6.99575C521.28248,239.785,533.83587,238.70653,533.896,237.31585Z"
              transform="translate(-201.25 -32.75)"
              fill="#2f2e41"
            />
            <circle cx="559" cy="744.5" r="43" fill="#6c63ff" />
            <circle cx="54" cy="729.5" r="43" fill="#6c63ff" />
            <circle cx="54" cy="672.5" r="31" fill="#6c63ff" />
            <circle cx="54" cy="624.5" r="22" fill="#6c63ff" />
          </svg>
          <h2 style={{ fontSize: "1.3rem", marginBottom: "20px" }}>Product Doesn't Exist</h2>
          <Link to={"/"}>See Other Products</Link>
        </div>
      )}

      <Outlet />
    </div>
  );
};

export default ProductDetail;
