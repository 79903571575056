import { useEffect, useRef, useState } from "react";
import { InputBox, TextArea } from "../inputBox/inputBox";
import "./editProduct.css";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ProductImages from "./productImages";
import useMyFetch from "../fetch/fetch";
import { useData } from "../dataProvider/dataProvider";

const EditProduct = ({ edit = true }) => {
  const { bikes, setBikes, productInfo, setProductInfo, productImageFolder } = useData();
  const [newImage, setNewImage] = useState(false);
  useEffect(() => {
    if (edit && !productInfo) {
      navigate("/");
      return;
    }
  }, []);

  const [name, setName] = useState(edit ? productInfo.name : "");
  const [price, setPrice] = useState(edit ? productInfo.price : "");
  const [detail, setDetail] = useState(edit ? productInfo.detail : "");
  const [photos, setPhotos] = useState([null]);

  const navigate = useNavigate();
  const { post } = useMyFetch();

  const nameInput = useRef(null);
  const priceInput = useRef(null);
  const detailInput = useRef(null);

  useEffect(() => {
    if (edit) {
      // Assume productInfo.images is a JSON string with file URLs
      const imageUrls = JSON.parse(productInfo.images).map((img) => productImageFolder + img.url);

      // Fetch files from URLs
      const fetchFiles = async () => {
        try {
          const filePromises = imageUrls.map(async (url) => {
            const response = await fetch(url);
            const blob = await response.blob();
            return new File([blob], url.split("/").pop(), { type: blob.type });
          });

          const files = await Promise.all(filePromises);
          setPhotos(files);
        } catch (error) {
          console.error("Error fetching files:", error);
        }
      };

      fetchFiles();
    } else {
      setPhotos([null]);
    }
  }, []);

  function KeyPressHandler(event, nextRef, isLast = false) {
    if (event.target.name === "price") {
      if (!event.key.match(/^\d$/)) {
        event.preventDefault();
      }
    }
    if (event.key === "Enter") {
      if (event.target.name === "detail") return;
      event.preventDefault();
      if (event.target.value !== "") {
        if (isLast) {
          if (edit) {
            ConfirmEdit();
            return;
          }
          ConfirmNewProduct();
        }
        if (nextRef && nextRef.current) {
          nextRef.current.focus();
        }
      }
    }
  }

  const ConfirmEdit = async () => {
    try {
      // Filter out empty photos
      const validPhotos = photos.filter((photo) => photo !== null);

      const tmpPhotos = validPhotos.map((img) => img);

      console.log(tmpPhotos);

      const productData = {
        id: productInfo.id,
        name,
        price,
        detail,
        photos: tmpPhotos.length,
        newImage,
      };

      if (newImage) {
        tmpPhotos.forEach((photo, index) => {
          const fileKey = `file${index + 1}`;
          productData[fileKey] = photo;
        });
      }

      // Send the product data to the server
      post("product/edit", productData).then((res) => {
        if (res.status !== "success") {
          toast.error(res.message);
        } else {
          toast.success("Product edited successfully!");
          setProductInfo(res.data);
          setBikes(bikes.map((item) => (item.id === productInfo.id ? res.data : item)));
          navigate(-1); // Navigate back to the previous page
        }
      });
    } catch (error) {
      console.error("Error processing photos:", error);
      toast.error("Failed to process images.");
    }
  };

  const ConfirmNewProduct = async () => {
    try {
      // Filter out empty photos
      const validPhotos = photos.filter((photo) => photo !== null);

      const tmpPhotos = validPhotos.map((img) => img);

      console.log(tmpPhotos);

      const productData = {
        name,
        price,
        detail,
        photos: tmpPhotos.length,
      };

      tmpPhotos.forEach((photo, index) => {
        const fileKey = `file${index + 1}`;
        productData[fileKey] = photo;
      });

      // Send the product data to the server
      post("product/add", productData).then((res) => {
        if (res.status !== "success") {
          toast.error(res.message);
        } else {
          toast.success("Product added successfully!");
          setBikes([...bikes, res.data]);
          navigate(-1); // Navigate back to the previous page
        }
      });
    } catch (error) {
      console.error("Error processing photos:", error);
      toast.error("Failed to process images.");
    }
  };

  return (
    <div className="editProduct">
      <header>
        <svg title="back" onClick={() => navigate(-1)}>
          <use xlinkHref="#back-icon" />
        </svg>
        <h1>{edit ? "Edit Product" : "New Product"}</h1>
      </header>
      <div className="body">
        <InputBox
          type="text"
          name="name"
          display="Name"
          value={name}
          setValue={setName}
          ref={nameInput}
          onKeyPress={(e) => KeyPressHandler(e, priceInput)}
          autoComplete="off"
          required={true}
        />

        <InputBox
          type="number"
          name="price"
          display="Price"
          value={price}
          setValue={setPrice}
          ref={priceInput}
          onKeyPress={(e) => KeyPressHandler(e, detailInput)}
          autoComplete="off"
          required={true}
        />

        <TextArea
          value={detail}
          name="detail"
          setValue={setDetail}
          display="Product Detail"
          ref={detailInput}
          onKeyPress={(e) => KeyPressHandler(e, null, true)}
        />

        <ProductImages photos={photos} setPhotos={setPhotos} setNewImage={setNewImage} />

        {edit ? (
          <button className="success" onClick={ConfirmEdit}>
            Save Changes
          </button>
        ) : (
          <button className="success" onClick={ConfirmNewProduct}>
            Create Product
          </button>
        )}
      </div>
    </div>
  );
};

export default EditProduct;
