import React, { useEffect, useRef, useState } from "react";
import "./inputBox.css";

const InputBox = React.forwardRef(
  (
    {
      type,
      name,
      display,
      value,
      setValue,
      className = "",
      isRequired = false,
      isPassword = false,
      ...props
    },
    ref
  ) => {
    const [visible, setVisible] = useState(false);
    const [trueType, setTrueType] = useState(type);

    return (
      <div className={"inputBox " + className}>
        <input
          type={trueType}
          name={name}
          value={value}
          required={isRequired}
          ref={ref}
          onChange={(e) => setValue(e.target.value)}
          {...props}
          onFocus={(e) => {
            e.target.scrollIntoView({ behavior: "smooth", block: "center" });
          }}
        />
        <span>{display}</span>

        {isPassword && !visible && (
          <svg
            onClick={() => {
              setVisible(true);
              setTrueType("text");
            }}
          >
            <use xlinkHref="#eye-icon" />
          </svg>
        )}
        {isPassword && visible && (
          <svg
            className="open"
            onClick={() => {
              setVisible(false);
              setTrueType("password");
            }}
          >
            <use xlinkHref="#eye-closed-icon" />
          </svg>
        )}
      </div>
    );
  }
);

const InputBox2 = React.forwardRef(
  (
    {
      type,
      name,
      display,
      value,
      setValue,
      className = "",
      isRequired = false,
      isPassword = false,
      ...props
    },
    ref
  ) => {
    const [visible, setVisible] = useState(false);
    const [trueType, setTrueType] = useState(type);

    return (
      <div className={"inputBox2 " + className}>
        <p>{display} : </p>
        <input
          type={trueType}
          name={name}
          value={value}
          required={isRequired}
          ref={ref}
          onChange={(e) => setValue(e.target.value)}
          {...props}
        />

        {isPassword && !visible && (
          <svg
            onClick={() => {
              setVisible(true);
              setTrueType("text");
            }}
          >
            <use xlinkHref="#eye-icon" />
          </svg>
        )}
        {isPassword && visible && (
          <svg
            className="open"
            onClick={() => {
              setVisible(false);
              setTrueType("password");
            }}
          >
            <use xlinkHref="#eye-closed-icon" />
          </svg>
        )}
      </div>
    );
  }
);
const SearchBox = React.forwardRef(
  ({ type, name, value, setValue, className = "", isRequired = false, ...props }, ref) => {
    return (
      <div className={"searchBox " + className}>
        <input
          type={type}
          name={name}
          value={value}
          required={isRequired}
          ref={ref}
          onChange={(e) => setValue(e.target.value)}
          {...props}
        />
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.0314 12.8995C15.0172 11.6672 15.6066 10.104 15.6066 8.40323C15.6066 4.42497 12.3816 1.19995 8.40335 1.19995C4.42509 1.19995 1.20007 4.42497 1.20007 8.40323C1.20007 12.3815 4.42509 15.6065 8.40335 15.6065C10.1042 15.6065 11.6673 15.0171 12.8996 14.0313L17.4338 18.5656C17.7463 18.8781 18.2531 18.8781 18.5657 18.5656C18.8782 18.253 18.8782 17.7462 18.5657 17.4336L14.0314 12.8995ZM8.40335 14.0138C5.30471 14.0138 2.79277 11.5019 2.79277 8.40323C2.79277 5.30459 5.30471 2.79265 8.40335 2.79265C11.502 2.79265 14.0139 5.30459 14.0139 8.40323C14.0139 11.5019 11.502 14.0138 8.40335 14.0138Z"
            fill="#0F0F0F"
          />
        </svg>
      </div>
    );
  }
);

const TextArea = React.forwardRef(
  ({ name, display, value, setValue, className = "", isRequired = false, ...props }, ref) => {
    return (
      <div className={"textArea " + className}>
        <textarea
          name={name}
          value={value}
          required={isRequired}
          ref={ref}
          onChange={(e) => setValue(e.target.value)}
          onFocus={(e) => {
            e.target.scrollIntoView({ behavior: "smooth", block: "center" });
          }}
          onInput={(e) => {
            e.target.style.height = "auto";
            e.target.style.height = `${e.target.scrollHeight}px`;
          }}
          {...props}
        />
        <p className={value.trim() !== "" ? "valid" : ""}>{display}</p>
      </div>
    );
  }
);

const SmallInput = React.forwardRef(
  (
    {
      name,
      display,
      value,
      setValue,
      className = "",
      isRequired = false,
      onKeyPress,
      onKeyDown,
      isHighlighted,
      isAnimating,
      ...props
    },
    ref
  ) => {
    const handleInputChange = (newValue) => {
      // Ensure newValue is numeric characters only
      const numericValue = newValue.replace(/\D/g, ""); // Remove non-numeric characters
      setValue(numericValue); // Update state with the cleaned numeric value
    };

    return (
      <input
        type="text"
        name={name}
        value={value}
        required={isRequired}
        ref={ref}
        onChange={(e) => handleInputChange(e.target.value)}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        className={
          className +
          (isHighlighted ? " highlight" : "") +
          (isHighlighted && isAnimating ? " shake" : "")
        }
        {...props}
      />
    );
  }
);

const SmallGroup = React.forwardRef(
  (
    {
      name,
      display,
      value,
      setValue,
      className = "",
      isRequired = false,
      highlightIndices = [],
      isAnimating = false,
      ...props
    },
    ref
  ) => {
    const [inputs, setInputs] = useState(value.map(String)); // State to manage input values
    const inputRefs = useRef([]); // Ref to hold input refs for focusing

    useEffect(() => {
      // Update the state when the 'value' prop changes
      setInputs(value.map(String));
    }, []);

    const handleAddInput = () => {
      // Add a new empty input
      setInputs((prevInputs) => [...prevInputs, ""]);
    };

    const handleInputChange = (index, newValue) => {
      // Update the input value at 'index'
      const updatedInputs = [...inputs];
      updatedInputs[index] = newValue;
      setInputs(updatedInputs);
    };

    const handleKeyPress = (index, event) => {
      // Handle Enter key press to add new input or focus next input
      if (event.key === "Enter") {
        event.preventDefault();
        if (inputs[index] !== "") {
          if (index === inputs.length - 1) {
            handleAddInput(); // Add new input if last one is not empty
            setTimeout(() => {
              inputRefs.current[index + 1].focus(); // Focus on new input
            }, 0);
          } else {
            inputRefs.current[index + 1].focus(); // Focus on next input
          }
        }
      }
    };

    const handleKeyDown = (index, event) => {
      // Handle Backspace key to delete empty input and focus previous input
      if (event.key === "Backspace" && inputs[index] === "") {
        event.preventDefault();
        if (inputs.length > 1) {
          setInputs((prevInputs) => prevInputs.filter((_, i) => i !== index)); // Remove empty input
          setTimeout(() => {
            if (index > 0) {
              inputRefs.current[index - 1].focus(); // Focus on previous input
            }
          }, 0);
        }
      }
    };

    const renderInputs = () => {
      // Render SmallInput components based on 'inputs' state
      return inputs.map((inputValue, index) => (
        <SmallInput
          key={index}
          ref={(el) => (inputRefs.current[index] = el)}
          name={`${name}-${index}`}
          display={`${display} ${index + 1}`}
          value={inputValue}
          setValue={(newValue) => handleInputChange(index, newValue)}
          onKeyPress={(event) => handleKeyPress(index, event)}
          onKeyDown={(event) => handleKeyDown(index, event)}
          className={className}
          isRequired={isRequired}
          isHighlighted={highlightIndices.includes(index)}
          isAnimating={isAnimating}
          {...props}
        />
      ));
    };

    useEffect(() => {
      setValue(inputs);
    }, [inputs]);

    return (
      <div className={"smallInputGroup " + className}>
        <p>{display} :</p>
        <div className="container" ref={ref} {...props}>
          {renderInputs()}
          <svg
            width="32"
            height="32"
            style={{
              padding: "8px",
              backgroundColor: "#1C274C",
              borderRadius: "20px",
              cursor: "pointer",
              marginLeft: "12px",
            }}
            onClick={handleAddInput}
          >
            <use xlinkHref="#plus-icon" />
          </svg>
        </div>
      </div>
    );
  }
);

export { InputBox, InputBox2, SearchBox, TextArea, SmallGroup, SmallInput };
