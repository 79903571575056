import { useNavigate } from "react-router-dom";
import PopupBox from "./popup";
import toast from "react-hot-toast";
import { useData } from "../dataProvider/dataProvider";
import useMyFetch from "../fetch/fetch";

const DeleteProduct = () => {
  const navigate = useNavigate();
  const { bikes, setBikes, productInfo } = useData();

  const { post } = useMyFetch();

  const ConfirmDelete = () => {
    post("product/delete", { id: productInfo.id }).then((res) => {
      if (res.status !== "success") {
        toast.error(res.message);
        return;
      }
      setBikes(bikes.filter((item) => item.id !== productInfo.id));
      toast.success("Product Deleted Successfully");
      navigate(-2);
    });
  };

  return (
    <PopupBox
      formName="delete-Product row"
      color="danger"
      close={() => navigate(-1)}
      actions={[
        { type: "cancel", name: "Cancel" },
        {
          type: "danger",
          name: "Delete",
          func: ConfirmDelete,
        },
      ]}
    >
      <div className="trash-can">
        <svg viewBox="0 0 24 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2.31258 25.8345C2.31258 27.5489 3.76571 28.9516 5.54175 28.9516H18.4584C20.2345 28.9516 21.6876 27.5489 21.6876 25.8345V7.13172H2.31258V25.8345ZM23.3022 2.45603H17.6511L16.0365 0.897461H7.96362L6.34904 2.45603H0.697998V5.57315H23.3022V2.45603Z"
            fill="white"
          />
        </svg>
      </div>
      <div className="right">
        <h3>Are you sure?</h3>
        <p>
          Do you really want to delete this Product?
          <br /> This process cannot be undone.
        </p>
      </div>
    </PopupBox>
  );
};

export default DeleteProduct;
