import React, { useEffect } from "react";
import ImagePicker from "../inputBox/imagePicker";

const ProductPhotos = ({ photos, setPhotos, setNewImage }) => {
  const handleAddImagePicker = () => {
    setPhotos([...photos, null]);
    setNewImage(true);
  };

  const handleSetImage = (index, image) => {
    const newPhotos = [...photos];
    newPhotos[index] = image;
    setPhotos(newPhotos);
    setNewImage(true);
  };

  const handleRemoveImagePicker = (index) => {
    if (photos.length > 1) {
      const newPhotos = [...photos];
      newPhotos.splice(index, 1);
      setPhotos(newPhotos);
      setNewImage(true);
    }
  };

  useEffect(() => {
    console.log(photos);
  }, [photos]);

  return (
    <div
      style={{
        padding: "0 20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h2 style={{ marginBottom: "12px" }}>Product photos</h2>

      {photos.map((image, index) => (
        <div key={index} style={{ marginBottom: "20px", position: "relative" }}>
          <ImagePicker
            selectedImage={image}
            setSelectedImage={(img) => handleSetImage(index, img)}
            setNewImage={() => {}}
          />
          {photos.length !== 1 && (
            <svg
              title="share"
              onClick={() => handleRemoveImagePicker(index)}
              style={{
                position: "absolute",
                top: "-20px",
                right: "-20px",
                width: "44px",
                height: "44px",
                padding: "2px",
              }}
            >
              <use xlinkHref="#cross-icon" />
            </svg>
          )}
        </div>
      ))}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          border: "1px dashed #000",
          borderRadius: "6px",
          padding: "20px",
        }}
      >
        <svg
          title="share"
          onClick={() => handleAddImagePicker()}
          style={{
            width: "44px",
            height: "44px",
            padding: "2px",
          }}
        >
          <use xlinkHref="#plus-dark-icon" />
        </svg>
        <h2>Add more</h2>
      </div>
    </div>
  );
};

export default ProductPhotos;
