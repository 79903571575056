import "./brandCard.css";
// import "./";

const BrandCard = () => {
  return (
    <div className="brandCard">
      <img src="logo512.png" alt="" />
    </div>
  );
};

export default BrandCard;
